import { dehydrate } from '@tanstack/react-query';
import type { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import requestIp from 'request-ip';
import { createFeaturedHallFilter } from '../components/organisms/Hall-products-featured/types';
import { Home as HomeTemplate, ProductSearch } from '../components/templates';
import {
    AuthService,
    BannerService,
    BlockService,
    CacheService,
    CategoryService,
    ElasticService,
    EstablishmentService
} from '../services';
import { IBlockConfig } from '../services/block/types';
import { queryClient } from '../services/query-client';
import {
    NEXT_PUBLIC_HOME_BANNER_1,
    NEXT_PUBLIC_HOME_BANNER_COUPON,
    NEXT_PUBLIC_HOME_BANNER_MOBILE,
    NEXT_PUBLIC_HOME_BANNER_PARTNERS,
    NEXT_PUBLIC_HOME_BANNER_PRODUCTS,
    NEXT_PUBLIC_HOME_BANNER_SELLERS
} from '../utils/envs';
import {
    BANNER,
    BLOCK_CONFIG,
    BLOCK_FOOTER_DELIVERY,
    BLOCK_HALL_HOME,
    CATEGORIES,
    ESTABLISHMENT,
    HALL,
    USER_LOGGED
} from '../utils/react-query-keys';
import { replaceUndefinedWithNull } from '../utils/string';
import { IBlockHallHome } from '../services/block';
import { useRouter } from 'next/router';

interface IHomeProps {
    detectedIp: string;
}

const Home: NextPage<IHomeProps> = (props) => {
    const router = useRouter();

    if (router?.query?.params?.length || router?.query?.q) {
        return <ProductSearch />;
    }
    return <HomeTemplate {...props} />;
};

export const getServerSidePropsCommon = ({ req, res }: GetServerSidePropsContext) => {
    return [
        queryClient.prefetchQuery([ESTABLISHMENT], () =>
            replaceUndefinedWithNull(EstablishmentService.getEstablishmentsStorage({ req, res }))
        ),
        // queryClient.prefetchQuery([USER_INFO], () => UserService.getInfo(undefined, { req, res })),
        queryClient.prefetchQuery([CATEGORIES], () =>
            CacheService.getCacheFirst(
                CATEGORIES,
                () => replaceUndefinedWithNull(CategoryService.initCategory()),
                60 * 60 * 2
            )
        ),
        queryClient.prefetchQuery([BLOCK_CONFIG], () =>
            CacheService.getCacheFirst(BLOCK_CONFIG, () => replaceUndefinedWithNull(BlockService.getGeneralInfos()))
        ),
        queryClient.prefetchQuery([BLOCK_FOOTER_DELIVERY], () =>
            CacheService.getCacheFirst(BLOCK_FOOTER_DELIVERY, () =>
                replaceUndefinedWithNull(BlockService.getBlockFooterDeliveryBanner())
            )
        ),
        queryClient.prefetchQuery([USER_LOGGED], () => replaceUndefinedWithNull(AuthService.checkLogged({ req, res })))
    ];
};

export const getServerSideProps: GetServerSideProps = async (params) => {
    if (params?.query?.q || params?.query?.params?.length) {
        await Promise.all(getServerSidePropsCommon(params));
        return {
            props: { dehydratedState: dehydrate(queryClient) }
        };
    }

    const hallHome: IBlockHallHome[] = await queryClient.fetchQuery([BLOCK_HALL_HOME], () =>
        CacheService.getCacheFirst(BLOCK_HALL_HOME, () => replaceUndefinedWithNull(BlockService.getBlockHallHome()))
    );

    await Promise.all([
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_1], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_1], () =>
                replaceUndefinedWithNull(BannerService.getById(NEXT_PUBLIC_HOME_BANNER_1))
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_SELLERS], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_SELLERS], () =>
                replaceUndefinedWithNull(BannerService.getById(NEXT_PUBLIC_HOME_BANNER_SELLERS))
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_PARTNERS], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_PARTNERS], () =>
                replaceUndefinedWithNull(BannerService.getById(NEXT_PUBLIC_HOME_BANNER_PARTNERS))
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_MOBILE], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_MOBILE], () =>
                replaceUndefinedWithNull(BannerService.getById(NEXT_PUBLIC_HOME_BANNER_MOBILE))
            )
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_PRODUCTS], () =>
            replaceUndefinedWithNull(BannerService.getBannerWithProducts(NEXT_PUBLIC_HOME_BANNER_PRODUCTS, params))
        ),
        queryClient.prefetchQuery([BANNER, NEXT_PUBLIC_HOME_BANNER_COUPON], () =>
            CacheService.getCacheFirst([BANNER, NEXT_PUBLIC_HOME_BANNER_COUPON], () =>
                replaceUndefinedWithNull(BannerService.getBannerResponsiveById(NEXT_PUBLIC_HOME_BANNER_COUPON))
            )
        ),

        ...hallHome.map((item, id) =>
            queryClient.prefetchQuery([HALL, item.title, id.toString()], () => {
                return ElasticService.getProducts({
                    filter: createFeaturedHallFilter(item.categories),
                    limit: 30,
                    page: 1,
                    cookiesParams: { req: params.req, res: params.res },
                    sort: item.categories.map((item) => ({
                        [`position_category_${item}`]: {
                            order: 'asc'
                        }
                    }))
                });
            })
        ),
        ...getServerSidePropsCommon(params)
    ]);
    const detectedIp =
        process.env.NODE_ENV === 'production'
            ? requestIp.getClientIp(params.req)
            : '2804:14c:5f82:4227:d930:9171:a651:604d';

    return {
        props: { dehydratedState: dehydrate(queryClient), detectedIp }
        // revalidate: 30 * 10 // In seconds
    };
};

export default Home;
